import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';
import { SessionProvider as NextAuthProvider } from 'next-auth/react';
import { withTRPC } from '@trpc/next';
import { httpLink } from '@trpc/client/links/httpLink';
import { httpBatchLink } from '@trpc/client/links/httpBatchLink';
import { getFetch } from '@trpc/react';
import superjson from 'superjson';
import { MotionConfig } from 'framer-motion';
import nextI18nextConfig from '~/next-i18next.config';
import '~/app/global.scss';
import type { MyAppProps } from '~/app/index.d';
import type { AppRouter } from '@vidbaseapp/api.vidbase';

function getEndingLink(url: string) {
  if (!process.browser) {
    return httpBatchLink({
      url
    });
  }

  return httpLink({
    url
  });
}

declare global {
  interface Window {
    workbox?: any;
    __vidbs?: boolean;
    structuredClone?: any;
    MCorp?: any;
    _ds?: any;
    _activeCues?: any;
    _timingVersion?: string;
    _to?: any;
    _dispatch?: any;
  }
}

const MyApp = ({
  Component,
  pageProps
}: MyAppProps) => {
  return <>
			<Head>
				<meta name="viewport" content="height=device-height,width=device-width,initial-scale=1.0, minimum-scale=1.0,maximum-scale=5.0,user-scalable=yes" />
				<style type="text/css">{`html,body {background-color:#1f2023; color:#fff;} body.loading * {display: none}`}</style>
				<link rel="prefetch" href="/fonts/Inter.var.woff2" as="font" type="font/woff2" />
				<link rel="prefetch" href="/fonts/IBMPlexMono-Regular.woff2" as="font" type="font/woff2" />
				<link rel="preload stylesheet" as="style" type="text/css" href="/reset.css" />
				<link rel="preconnect" href="https://api.sjpf.io" />
				<link rel="preconnect" href="https://fp.vidbase.app" />
				<link rel="preconnect" href="https://fpjs.vidbase.app" />
				<link rel="preconnect" href={process.env.NEXTAUTH_URL} />
			</Head>
			<ThemeProvider defaultTheme={'dark'} enableSystem={false}>
				{
        /* <NextAuthProvider
        session={pageProps.session || {}}
        basePath={
        	(process.env.NEXT_PUBLIC_NEXTAUTH_URL || 'http://localhost:4000') + '/api/auth'
        }> */
      }
				<MotionConfig reducedMotion="user">
					<Component {...pageProps} />
				</MotionConfig>
				{
        /* </NextAuthProvider> */
      }
			</ThemeProvider>
		</>;
};

export default withTRPC<AppRouter>({
  ssr: true,

  config() {
    const url = process.env.API_HOST ? `${process.env.API_HOST}/api/trpc` : 'http://localhost:4000/api/trpc';
    return {
      url,
      transformer: superjson,
      links: [runtime => {
        return linkctx => {
          const link = httpBatchLink({
            url
          })({ ...runtime,
            fetch: (f_url, options) => {
              const fetch = getFetch();
              return fetch(f_url, {
                credentials: 'include',
                ...options
              });
            }
          });
          link({ ...linkctx
          });
        };
      }],
      queryClientConfig: {
        defaultOptions: {
          queries: {
            staleTime: 60
          }
        }
      }
    };
  }

})(appWithTranslation((MyApp as any), nextI18nextConfig));